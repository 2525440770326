import logo from './logo.svg';
import './App.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Entry from './components/Entry';
import { ActivePageProvider } from './components/ActivePageContext';
import About from './components/About';
import Contact from './components/Contact';
import Spells from './components/Spells';
import Charms from './components/Charms';
import { AnimatePresence, motion } from "framer-motion";

function App() {
  const location = useLocation();
  return (
    <div className="App">
      <ActivePageProvider>
        <AnimatePresence mode="wait">
          <Routes location={location} key={location.pathname}>
            <Route path="/" element={<AnimatedPage><Entry /></AnimatedPage>} />
            <Route path="/about" element={<AnimatedPage><About /> </AnimatedPage>} />
            <Route path="/contact-us" element={<AnimatedPage><Contact /></AnimatedPage>} />
            <Route path="/spells/:name" element={<AnimatedPage><Spells /></AnimatedPage>} />
            <Route path="/charms/:name" element={<AnimatedPage><Charms /></AnimatedPage>} />
          </Routes>
        </AnimatePresence>
      </ActivePageProvider>
    </div>
  );
}

const AnimatedPage = ({ children }) => {
  return (
    <motion.div
      initial={{
        opacity: 0,
        scale: 0.9,
        y: 50,
        transition: { duration: 1 }
      }}
      animate={{
        opacity: 1,
        scale: 1,
        y: 0,
        transition: { duration: 1 }
      }}
      exit={{
        opacity: 0,
        scale: 0.95,
        y: -50,
        transition: { duration: 1 }
      }}
    //transition={{ duration: 1 }}
    >
      {children}
    </motion.div>
  );
};

export default App;
