import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "../css/Whatsapp.css";

const Whatsapp = () => {
    const [opened, setOpened] = useState(false);

    const openChat = () => {
        setOpened((prev) => !prev);
    };

    return (
        <div>
            {/* Floating WhatsApp Button with Hover & Tap Effects */}
            <motion.button
                className="tsupLink"
                onClick={openChat}
                aria-label="Open WhatsApp Chat"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
            >
                <i className="fab fa-whatsapp" aria-hidden="true"></i>
            </motion.button>

            <AnimatePresence>
                {opened && (
                    <motion.div
                        className="tsupLinkPopup"
                        initial={{ opacity: 0, scale: 0.8, y: 20 }}
                        animate={{ opacity: 1, scale: 1, y: 0 }}
                        exit={{ opacity: 0, scale: 0.8, y: 20 }}
                        transition={{ duration: 0.3 }}
                    >
                        <div>
                            <i className="fab fa-whatsapp" aria-hidden="true"></i>
                            <p className="text-white">
                                Welcome to PowerWitchery Site
                                <br />
                                <span style={{ fontSize: "0.875rem" }}>
                                    Chat with customer support
                                </span>
                            </p>
                        </div>
                        <div className="detailed">
                            <img src="/images/logo.png" alt="PowerWitchery Logo" />
                            <motion.a
                                href="https://wa.me/256757646679/?text=Hello!"
                                target="_blank"
                                rel="noopener noreferrer"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                className="whatsapp-link"
                            >
                                <span>Online</span>
                                <h2>Click to chat with us</h2>
                            </motion.a>
                        </div>
                        <p className="text-muted">We are online from 8 AM to 12 AM</p>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Whatsapp;
