import React from 'react'
import "../css/ContactFormFooter.css"
import { motion } from 'framer-motion'

const ContactFormFooter = () => {
    return (
        <motion.div initial={{ opacity: 0, x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.8, delay: 0.2 }}>
            <div className='text-center flex-col flex align-items-center bkFooter align-middle'>
                <h4 className='text-danger'>Free Consultation</h4>
                <h2 className='text-white'>Do You Have any Questions</h2>
            </div>
            <div className='footerForm'>
                <div className='leftSide'>
                    <h3 className='text-white'>+27(78) 8141278</h3>
                    <h1 className='text-danger'>NEED <br />CONSULTATION</h1>
                    <p className='text-center text-white'>Feel free to contact us and ask your question, its absolutely free</p>
                </div>
                <form>
                    <div>
                        <input type='text' placeholder='Your Name' />
                        <input type='text' placeholder='Your Email' />
                    </div>
                    <div>
                        <input type='text' placeholder='Subject' />
                        <select>
                            <option selected value={""}>selct an option</option>
                        </select>
                    </div>
                    <textarea placeholder='Your Message'></textarea>
                    <p className='flex justify-end'>
                        <button className='btn btn-danger'>Send Message</button>
                    </p>
                </form>
            </div>
        </motion.div>
    )
}

export default ContactFormFooter