import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import "../css/Navigator.css"
import { motion, AnimatePresence } from "framer-motion";
import { useActivePage } from './ActivePageContext'

const Navigator = () => {
    const { activePage, setActivePage } = useActivePage();
    const [isVisible, setIsVisible] = useState(false);
    const handlePageChange = (page) => {
        setActivePage(page);
    };
    const handleMenu = () => {
        if (isVisible) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    }
    return (
        <div className='nav-menu d-flex w-100 justify-content-between align-items-center'>
            <h1 className='fs-4 fw-bolder text-success'>Power Witchery</h1>
            <Link className='menu' onClick={handleMenu}><i className="fa-solid fa-bars"></i> Menu</Link>
            <nav className='d-none d-lg-flex'>
                <Link to={'/'} onClick={() => handlePageChange('home')} className={activePage === 'home' ? 'activePage' : ''}>Home</Link>
                <Link to={'/about'} onClick={() => handlePageChange('about')} className={activePage === 'about' ? 'activePage' : ''}>About</Link>
                <Link className={activePage === 'magic-bracelet' || activePage === 'magic-ring' || activePage === 'magic-oil' ? 'activePage' : ''}>
                    Margic Charms
                    <div>
                        <Link to={'/charms/magic-bracelet'} onClick={() => handlePageChange('magic-bracelet')}>Magic Bracelets</Link>
                        <Link to={'/charms/magic-ring'} onClick={() => handlePageChange('magic-ring')}>Magic Rings</Link>
                        <Link to={'/charms/magic-oils'} onClick={() => handlePageChange('magic-oil')}>Magic Oils</Link>
                    </div>
                </Link>
                <Link to={'/contact-us'} onClick={() => handlePageChange('contact')} className={activePage === 'contact' ? 'activePage' : ''}>Contact</Link>
            </nav>
            <AnimatePresence>
             {isVisible && (
                    <motion.nav initial={{ opacity: 0, x: 50 }} // Slide from right
                        animate={{ opacity: 1, x: 0 }}  // Appear smoothly
                        exit={{ opacity: 0, x: 50 }}    // Slide out on close
                        transition={{ duration: 0.5 }} className={isVisible ? 'd-block' : ''}>
                        <Link to={'/'} onClick={() => handlePageChange('home')} className={activePage === 'home' ? 'activePage' : ''}>Home</Link>
                        <Link to={'/about'} onClick={() => handlePageChange('about')} className={activePage === 'about' ? 'activePage' : ''}>About</Link>
                        <Link className={activePage === 'magic-bracelet' || activePage === 'magic-ring' || activePage === 'magic-oil' ? 'activePage' : ''}>
                            Margic Charms
                            <div>
                                <Link to={'/charms/magic-bracelet'} onClick={() => handlePageChange('magic-bracelet')}>Magic Bracelets</Link>
                                <Link to={'/charms/magic-ring'} onClick={() => handlePageChange('magic-ring')}>Magic Rings</Link>
                                <Link to={'/charms/magic-oils'} onClick={() => handlePageChange('magic-oil')}>Magic Oils</Link>
                            </div>
                        </Link>
                        <Link to={'/contact-us'} onClick={() => handlePageChange('contact')} className={activePage === 'contact' ? 'activePage' : ''}>Contact</Link>
                    </motion.nav>
                )}
            </AnimatePresence>
        </div>
    )
}

export default Navigator

/*
<Link className={activePage === 'love-spell' || activePage === 'protection-spell' || activePage === 'money-spell' ? 'activePage' : ''}>
    PowerFull Spells
    <div>
        <Link to={'/spells/love-spell'} onClick={() => handlePageChange('love-spell')} >Love Spell</Link>
        <Link to={'/spells/protection-spell'} onClick={() => handlePageChange('protection-spell')}>Protection Spell</Link>
        <Link to={'/spells/money-spell'} onClick={() => handlePageChange('money-spell')}>Money Spell</Link>
    </div>
</Link>
*/