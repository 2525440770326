import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../css/SpellSlider.css"


const slides = [
    {
        image: "/images/1.jpg",
        heading: "Powerfull Spells",
        subheading: "Unleash the Power of True Magic! Magic is energy, intention, and will combined. Our powerful spells are designed to help you shape your reality, align with the universe, and bring your deepest desires to life. Whether you seek love, protection, prosperity, healing, or transformation, each spell is crafted with potent energies, sacred rituals, and time-honored wisdom. From candle magic to intricate rituals, our spells empower you to tap into the unseen forces and make magic work for you. Step into your power, trust your intuition, and watch the universe respond. 🌙 Your destiny is in your hands—cast with confidence! ✨",
    },
    {
        image: "/images/15.jpg",
        heading: "Magic Crystals, Unlock the Power of the Earth!",
        subheading: "Crystals are nature's gifts, each one radiating unique energy that can amplify your intentions, enhance your spiritual practice, and bring balance to your life. From protection to healing, abundance to transformation, magic crystals help you tap into the earth's ancient wisdom and the universe's powerful forces. Whether you seek clarity, emotional healing, or energetic protection, our curated selection of crystals offers the perfect vibrational match for your needs. Carry them with you, use them in your rituals, or place them in your sacred space to invoke their power. 🔮 Awaken your magic with the crystal that calls to you. 🌙✨",
    },
    {
        image: "/images/ad5.jpg",
        heading: "Magic Charms, Enchant Your Life",
        subheading: "Magic charms are powerful tools of protection, luck, love, and manifestation. Infused with intention and energy, these enchanted objects serve as conduits for your desires, helping you attract positivity and ward off negativity. Whether crafted from crystals, herbs, sigils, or sacred symbols, each charm is uniquely designed to align with your personal magic. Explore our collection and discover the perfect charm to amplify your power and guide your journey.",
    }
];

const SpellSlider = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [direction, setDirection] = useState(1); // 1 for next, -1 for previous

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 15000); // Change slide every 10 seconds
        return () => clearInterval(interval);
    }, []);

    const handleNext = () => {
        setDirection(1);
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handlePrev = () => {
        setDirection(-1);
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };


    return (
        <motion.div initial={{ opacity: 0, y: -100 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true, amount: 0.2 }}
            transition={{ duration: 0.8, delay: 0.5 }} className="sliderSpell">
            <AnimatePresence mode="wait">
                <motion.div
                    key={currentSlide}
                    className="absolute w-full h-full"
                    initial={{ x: direction * 100, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    exit={{ x: -direction * 100, opacity: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    style={{
                        backgroundImage: `url(${slides[currentSlide].image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}>
                    <motion.div
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 1, opacity: 0 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        className="contentSpell" >
                        <motion.h1
                            className="text-danger"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1, duration: 1 }}
                        >
                            {slides[currentSlide].heading}
                        </motion.h1>
                        <motion.p
                            className="mt-4 text-success"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.5, duration: 1 }}
                        >
                            {slides[currentSlide].subheading}
                        </motion.p>
                    </motion.div>
                </motion.div>
            </AnimatePresence>

            {/* Navigation Buttons */}
            <button
                onClick={handlePrev}
                className="slideBtns btns1"
            >
                <i className="fa-solid fa-angle-left"></i>
            </button>
            <button
                onClick={handleNext}
                className="slideBtns btns2"
            >
                <i className="fa-solid fa-angle-right"></i>
            </button>
        </motion.div>
    )
}


export default SpellSlider

/*
style={{ background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)" }}
    <motion.div
        key={currentSlide}
        className="absolute w-full h-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        style={{
            backgroundImage: `url(${slides[currentSlide].image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
    <motion.div
        key={currentSlide}
        className="absolute w-full h-full"
        initial={{ x: direction * 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -direction * 100, opacity: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        style={{
            backgroundImage: `url(${slides[currentSlide].image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
*/