import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import "../css/About.css"
const About = () => {
  return (
    <div>
        <Top />
        <Navigator />
        <Banner page={'About Us'}/>
        <div className="main_heading text-center">
            <h2>Dedicated to serving you</h2>
            <p className="large">Fastest services with best price!</p>
        </div>
        <div className="row about_blog">
            <div className="col-lg-6 col-md-6 col-sm-12 about_cont_blog">
                <div className="full text_align_left">
                <h3>What we do</h3>
                <p>From healing remedies to divination, from rituals celebrating nature's cycles to the invocation of mystical forces. witchery has been historically misunderstood and subjected to prejudice and fear.</p>
                <p>Come Experience True African power</p>
                <ul style={{listStyle: 'none'}}>
                    <li><i className="fa fa-check-circle"></i>Magic Spells</li>
                    <li><i className="fa fa-check-circle"></i>Love Charms</li>
                    <li><i className="fa fa-check-circle"></i>Lost Lovers</li>
                    <li><i className="fa fa-check-circle"></i>Item Recovery</li>
                </ul>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="full text_align_center"> <img className="img-fluid" src="images/Love-charms.jpg" alt="#" /> </div>
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default About