import React, { useRef } from 'react'
import "../css/Body.css"
import { Link } from 'react-router-dom'
import SpellSlider from './SpellSlider'
import ContactFormFooter from './ContactFormFooter'
import { motion, useInView } from "framer-motion";

const Spell = ({ name, invert = false }) => {

    const spells = [
        {
            id: 1,
            name: 'Love Spell',
            description: `
            <h4>Our Love Spells have been used in</h4>
            <ul>
                <li>Rituals and Practices</li>
                <li>Cultural and Spiritual Significance</li>
                <li>Control or Domination</li>
                <li>Chants</li>
            </ul>
            <p>Our spells attract love into your life, while others focus on strengthening existing relationships</p>
            <p>the decision of whether or not to engage in love spells is a personal one. If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/witch.jpeg'
        },
        {
            id: 2,
            name: 'Protection Spell',
            description: `
            <h4>Get Your Protection Spell Now! </h4>
            <ul>
                <li><b>Cleanse: </b>These spells remove negative energy from your space or aura, promoting peace and positivity.</li>
                <br />
                <li><b>Shield: </b>They create a protective barrier around you, deflecting negativity and harmful intentions</li>
                <br />
                <li><b>Banish: </b>Used to expel negative forces, entities, or unwanted influences from your life.</li>
                <br />
                <li><b>Bind:</b> Restricts the influence of negative individuals or situations, preventing them from causing harm.</li>
            </ul>
            <p>Protection spells are a powerful tool for safeguarding yourself and your loved ones from negativity, harm, and danger. They come in many forms and can be tailored to specific needs</p>
            <p>If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/witch.jpeg'
        },
        {
            id: 3,
            name: 'Money Spell',
            description: `
            <h4>Money Spells</h4>
            <ul>
                <li><b>Attract: </b>These spells focus on drawing money and financial opportunities towards you. This can involve visualization, affirmations, or working with specific symbols or ingredients.</li>
                <br />
                <li><b>Prosper:</b> Aim to increase your overall abundance and flow of wealth. They may involve rituals for abundance, gratitude practices, or offerings to deities associated with prosperity.</li>
                <br />
                <li><b>Debt Clearing: </b>Specifically target eliminating existing financial burdens and debts. This can involve burning symbolic representations of debt or rituals involving clearing negative energy.</li>
                <br />
                <li><b>Career Success:</b> Focus on attracting opportunities for advancement, promotion, and financial success in your career. This can involve chants for career growth, offerings to deities of work and success, or visualization exercises.</li>
            </ul>
            <p>Money spells are rituals or practices aimed at attracting wealth, prosperity, and financial abundance into your life. </p>
            <p>If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/img1.avif'
        }
    ]

    const parentVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Each child animates with a 0.3s delay
                delayChildren: 0.2,   // Start children animation after 0.2s
            },
        },
    };

    const leftChildVariants = {
        hidden: { x: -200, opacity: 0 },
        show: { x: 0, opacity: 1, transition: { duration: 0.8 } },
    };

    const rightChildVariants = {
        hidden: { x: 200, opacity: 0 },
        show: { x: 0, opacity: 1, transition: { duration: 0.8 } },
    };

    return (
        <div>
            {invert === false ?
                <motion.div variants={parentVariants}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.2 }} className='spells row align-items-center'>
                    <motion.div variants={leftChildVariants} className='col-md-6'>
                        <p dangerouslySetInnerHTML={{ __html: name === 'love-spell' && spells[0].description || name === 'protection-spell' && spells[1].description || name === 'money-spell' && spells[2].description }}></p>
                    </motion.div>
                    <motion.div variants={rightChildVariants} className='col-md-6'>
                        <img src={name === 'love-spell' && spells[0].image || name === 'protection-spell' && spells[1].image || name === 'money-spell' && spells[2].image} />
                    </motion.div>
                </motion.div>
                :
                <motion.div variants={parentVariants}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.2 }} className='spells row align-items-center'>
                    <motion.div variants={leftChildVariants} className='col-md-6'>
                        <img src={name === 'love-spell' && spells[0].image || name === 'protection-spell' && spells[1].image || name === 'money-spell' && spells[2].image} />
                    </motion.div>
                    <motion.div variants={rightChildVariants} className='col-md-6'>
                        <p dangerouslySetInnerHTML={{ __html: name === 'love-spell' && spells[0].description || name === 'protection-spell' && spells[1].description || name === 'money-spell' && spells[2].description }}></p>
                    </motion.div>
                </motion.div>
            }
        </div>
    )
}

const Body = () => {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, amount: 0.5 });

    const parentVariants = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.3, // Each child animates with a 0.3s delay
                delayChildren: 0.2,   // Start children animation after 0.2s
            },
        },
    };

    const leftChildVariants = {
        hidden: { x: -200, opacity: 0 },
        show: { x: 0, opacity: 1, transition: { duration: 0.8 } },
    };

    const rightChildVariants = {
        hidden: { x: 200, opacity: 0 },
        show: { x: 0, opacity: 1, transition: { duration: 0.8 } },
    };


    return (
        <>
            <div className='body'>
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isInView ? { opacity: 1, y: 0 } : {}}
                    transition={{ duration: 0.8 }} className="main_heading text-center">
                    <h2 className='text-center'>{isInView ? "I'm visible!" : "Scroll to see me!"}Find Your Way to happiness through the most powerful spell caster</h2>
                </motion.div>
                <motion.div
                    variants={parentVariants}
                    initial="hidden"
                    whileInView="show"
                    viewport={{ once: true, amount: 0.2 }}
                    className="dividedTwo"
                >
                    <motion.div variants={leftChildVariants}></motion.div>
                    <motion.div variants={rightChildVariants}>

                        <p>We believe magic is not just a practice—it's a way of life. Rooted in ancient traditions yet attuned to modern energy, our mission is to empower
                            mystics, and spiritual seekers with the knowledge, tools, and confidence to wield their power fearlessly.

                            From potent rituals to enchanted tools, we are here to guide you in awakening your inner magic.</p>

                        <p>Join us as we explore the mysteries of the universe, harness the power within, and manifest a reality infused with energy, intention, and transformation.</p>
                        <p className='pt-3 pb-3'>🌙 Step into your Power. Embrace your Magic. 🔮</p>
                        <p className='d-none'>Delve into our ancient grimoires, where whispered secrets and ancient incantations converge, offering a tapestry of potent spells designed to illuminate the path to true bliss. From love charms that weave connections as eternal as the stars, to prosperity spells that beckon abundance into your existence, our offerings span the spectrum of desires, catering to every soul's quest for happiness.</p>
                        <p>Amidst the flickering candlelight and fragrant herbs, our adept sorcerers channel the energies of the universe, infusing each spell with an infusion of hope, positivity, and unyielding optimism. Trust in their wisdom as they craft bespoke enchantments, meticulously tailored to align with your deepest aspirations, paving the way to a life adorned with smiles and serenity.</p>

                        <p className='d-none'>Unearth the ancient wisdom passed down through generations, ensconced within the sacred scrolls and mystical artifacts that adorn our hallowed halls. These artifacts hold the essence of forgotten lore, ready to unfurl the secrets that lead to the happiness you seek.</p>
                    </motion.div>
                </motion.div>

                <motion.p initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.8 }}>Embrace the power of intention and manifestation as you harness the energies surrounding you. Our spells serve as conduits, channeling your desires into the universe's cosmic fabric, ensuring that your quest for happiness resonates throughout time and space.</motion.p>
                <motion.p initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.8, delay: 0.5 }}>Through our sacred rites and invocations, transcend the mundane and traverse realms where dreams take flight and happiness becomes an unwavering companion. Trust in the unseen forces that converge to usher in a life brimming with the purest form of joy.</motion.p>
            </div>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.8 }}
                className="main_heading text-center">
                <h2>Our Magic, Our Story. The Spirit of Power Witchery!</h2>
                <p className="large text-center">Mystical Offerings, Unlock Your Power!</p>
            </motion.div>
            <SpellSlider />
            <Spell name={"protection-spell"} />
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.8 }} className="main_heading text-center">
                <h2>Explore More..</h2>
                <p className="large text-center">Fastest services with best price!</p>
            </motion.div>
            <Spell name={"money-spell"} invert={true} />
            <div className='spels-charms'>
                <motion.div initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.8 }} className="main_heading text-center">
                    <h2>Looking For a Powerful Spell? </h2>
                    <p className="large text-center">Search No More!</p>
                </motion.div>
                <motion.p initial={{ opacity: 0, y: 50 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.8, delay: 0.5 }} className='wd-75 m-auto pb-4 text-secondary'>Dare to believe in the allure of magic; let its essence infuse every facet of your being. Embrace the enchantments, heed the guidance, and embark on a transformative journey towards an existence illuminated by the radiance of true happiness."</motion.p>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={{
                        hidden: { opacity: 0 },
                        visible: {
                            opacity: 1,
                            transition: { staggerChildren: 0.3 } // Staggers each child by 0.3s
                        }
                    }} className='d-flex charmed flex-wrap gap-4'>
                    <motion.div
                        variants={{ hidden: { opacity: 0, y: 100 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } }}
                        className='flex-grow-1'>
                        <h3>Love Spells</h3>
                        <p>Are you out of each other’s lives, need to stop a divorce or want marriage? You can get you lost lover back by using my powerful Love Spells</p>
                        <Link to={"/spells/love-spell"} className='btn btn-sm btn-outline-success rounded-pill mb-2'>Read More ..</Link>
                    </motion.div>
                    <motion.div
                        variants={{ hidden: { opacity: 0, y: 100 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } }}
                        className='flex-grow-1'>
                        <h3>Herbal, Candle & Crystal Magic</h3>
                        <p>Personalized charms, talismans, and remedies for healing and empowerment.</p>
                        <Link to={"/spells/herbal-spell"} className='btn btn-sm btn-outline-success rounded-pill mb-2'>Read More ..</Link>
                    </motion.div>
                    <motion.div
                        variants={{ hidden: { opacity: 0, y: 100 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } }}
                        className='flex-grow-1'>
                        <h3>Guidance & Mentorship</h3>
                        <p>This mentorship fosters empowerment, confidence, and personal transformation on your magical journey.</p>
                        <Link to={"/spells/guidance"} className='btn btn-sm btn-outline-success rounded-pill mb-2'>Read More ..</Link>
                    </motion.div>
                </motion.div>
                <ContactFormFooter />
                <motion.div initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.8 }} className="main_heading text-center">
                    <h2>Come get an extreme Charm of interest</h2>
                </motion.div>

                <motion.p initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true, amount: 0.2 }}
                    transition={{ duration: 0.8, delay: 0.5 }} className='wd-75 m-auto pb-4 text-secondary'>Dare to believe in the allure of magic; let its essence infuse every facet of your being. Embrace the enchantments, heed the guidance, and embark on a transformative journey towards an existence illuminated by the radiance of true happiness."</motion.p>
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                    variants={{
                        hidden: { opacity: 0 },
                        visible: {
                            opacity: 1,
                            transition: { staggerChildren: 0.3 } // Staggers each child by 0.3s
                        }
                    }} className='d-flex charmed2 flex-wrap gap-4 pb-4'>
                    <motion.div variants={{ hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } }} className='flex-grow-1'>
                        <h3>Magic Ring</h3>
                        <p className='text-white'>This powerful enchanted tool can be used for protection, manifestation, and spiritual connection.
                            Infused with magical intent, gemstones, and symbols, it serves as a conduit for energy, enhancing the wearer's abilities and intentions.
                            Are you out of each other’s lives, do you need to stop a divorce or want marriage? Get back your Lover Now!</p>
                        <Link to={"/charms/magic-ring"} className='btn btn-sm btn-outline-light rounded-pill mb-2 mt-2'>Read More ..</Link>
                    </motion.div>
                    <motion.div variants={{ hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } }} className='flex-grow-1 bg-secondary'>
                        <h3>Magic Oil</h3>
                        <p className='text-white'>Unlock the power of handcrafted Magic Oils, specially blended to enhance your spiritual practice, manifestation, and energy work. Each bottle is
                            infused with powerful herbs, essential oils, and charged elements to align with your intentions.
                            Elevate your craft with a powerful magic oil, designed just for you. Order yours today and let the magic flow!</p>
                        <Link to={"/charms/magic-oils"} className='btn btn-sm btn-outline-light rounded-pill mb-2 mt-2'>Read More ..</Link>
                    </motion.div>
                    <motion.div variants={{ hidden: { opacity: 0, y: 50 }, visible: { opacity: 1, y: 0, transition: { duration: 0.8 } } }} className='flex-grow-1 bg-danger'>
                        <h3>Magic Bracelet</h3>
                        <p className='text-white'>Harness the power of intention with a handcrafted Magic Bracelet, designed to align with your energy and spiritual goals. Each bracelet is enchanted with
                            powerful symbols, crystals, and sacred blessings to enhance your magic & manifestation. Elevate your spiritual practice with a custom-crafted magic bracelet, created just for you. Order yours today & embrace the magic!</p>
                        <Link to={"/charms/magic-bracelet"} className='btn btn-sm btn-outline-light rounded-pill mb-2 mt-2'>Read More ..</Link>
                    </motion.div>
                </motion.div>
            </div>
        </>
    )
}

export default Body

/*

<div className='d-block d-md-flex gap-4 charms'>
    <div className='flex-grow-1'>
        <h3 className='text-center text-white d-none'>Powerfull Love & Money</h3>
        <h2 className='text-white'>Powerfull Love & Money Spells</h2>
        <h4 className='text-white'>100% Guarantee</h4>
        <img className='w-100 mb-3' src='/images/Love-charms.jpg' />
    </div>
    <div className='flex-grow-1'>
        <img className='w-100' src='/images/stand_5757.jpg' />
        <p>Delve into our ancient grimoires, where whispered secrets and ancient incantations converge, offering a tapestry of potent spells designed to illuminate the path to true bliss.</p>
    </div>
    <div className='flex-grow-1'>
        <p>From love charms that weave connections as eternal as the stars, to prosperity spells that beckon abundance into your existence, our offerings span the spectrum of desires, catering to every soul's quest for happiness.</p>
        <img className='w-100' src='/images/witch.jpeg' />
    </div>
</div>
*/