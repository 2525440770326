import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import { useParams } from 'react-router-dom'
import "../css/Spells.css"
//name.replaceAll("-"," ")

const Spells = () => {
    const {name} = useParams()
    const spells = [
        {
            id:1,
            name:'Love Spell',
            description: `
            <h4>Our Love Spells have been used in</h4>
            <ul>
                <li>Rituals and Practices</li>
                <li>Cultural and Spiritual Significance</li>
                <li>Control or Domination</li>
                <li>Chants</li>
            </ul>
            <p>Our spells attract love into your life, while others focus on strengthening existing relationships</p>
            <p>the decision of whether or not to engage in love spells is a personal one. If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/ad1.jpg'
        },
        {
            id:2,
            name:'Protection Spell',
            description: `
            <h4>Herbal, Candle and Crystical Magic!</h4>
            <ul>
                <li>Welcome to my sacred space, where the ancient wisdom of herbs, the gentle glow of candles and the vibrant energy of crystals come together to weave
                a powerful magic. As a practitioner of holistic magic, Ibelieve that the natural world holds the key to unlocking our deepest potential and manifesting our
                wildest dreams.</li>
                <li>With a deep reverence for the earth and its ryhthms, I craft spells and rituals that harness the unique properties of herbs, candles and crystals to bring balance, harmony and transformation
                 to our lives. From the soothing balm of chamomile to the radiant light of citrine, each element is carefully selected to amplify our intentions and connect us with the divine.</li>
                <li>Whether you're seeking to ignite passion, cultivate abundance or simply find peace in a chaotic world, I invite you to join me on this journey 
                into the mystical realms of herbal, candle and crystal magic.</li>
                <li>Let us embark on a path of discovery, growth and enchantment as we tap into the secrets of the natural world and unlock the magic that lies within and around us.</li>
            </ul>
            
            <p>If you choose to explore this, do not hesitate to reach out.</p>
            <p></p>
            `,
            image: '/images/ad7.jpg'
        },
        {
            id:3,
            name:'Money Spell ----- put here guidance stuff',
            description: `
            <h4>Our Magic and Witchery Guidance & Mentorship program</h4> 
            <ul>
                <li>We provide personalized support for individuals seeking to deepen their spiritual and magical practice. Whether you're a beginner or looking to refine your craft, this mentorship offers:</li>
                <li>🔮 Foundational Teachings – Learn the basics of herbal magic, candle magic, crystal magic, and more.</li>
                <li>🌙 Personalized Guidance – Tailored lessons and practices based on your path and interests.</li>
                <li>🕯️ Ritual & Spellcrafting Support – Assistance in crafting spells, rituals, and manifestation techniques.</li>
                <li>📜 Esoteric Wisdom – Dive into the history, ethics, and philosophy of witchcraft.</li>
                <li>✨ Energy Work & Intuition Development – Strengthen your connection to your inner power and spiritual guides.</li>
                <li>🌿 Seasonal & Lunar Magic – Align with the cycles of nature for enhanced spellwork.</li>
                <li>🧿 Protection & Banishing Techniques – Learn how to shield your energy and space from negativity.</li>
            </ul>
            <p> </p>
            <p>If you choose to explore this, please contact us with your desired request</p>
            <p></p>
            `,
            image: '/images/r.jpg'
        }
    ]
    return (
        <div>
            <Top />
            <Navigator />
            <Banner page={name.replaceAll("-"," ")}/>
            <div className="main_heading text-center">
                <h2>We are a call away</h2>
                <p className="large"></p>
            </div>
            <div className='spells row'>
                <div className='col-md-6'>
                    <p dangerouslySetInnerHTML={{__html: name === 'love-spell' && spells[0].description || name === 'herbal-spell' && spells[1].description || name === 'guidance' && spells[2].description}}></p>
                </div>
                <div className='col-md-6'>
                    <img src={name === 'love-spell' && spells[0].image || name === 'herbal-spell' && spells[1].image || name === 'guidance' && spells[2].image} />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Spells