import React from 'react'
import Navigator from './Navigator'
import Footer from './Footer'
import Top from './Top'
import Banner from './Banner'
import "../css/Contact.css"
const Contact = () => {
  return (
    <div>
        <Top />
        <Navigator />
        <Banner page={'Contact Us'}/>
        <div className="main_heading text-center">
          <h2>We are a call away</h2>
          <p className="large">Ready to Serve, No Matter What!</p>
        </div>
        <div className='row adress_cont m-auto'>
          <div className="col-md-6">
            <h4>Contact Information</h4>
            <p>Just a text or Call Away!</p>
            <div className="d-flex align-items-center gap-4">
              <i className="fa fa-road" aria-hidden="true"></i>
              <div className='w-100'>
                <h4>Kyanja - komamboga</h4>
                <p>Kampala (U)</p>
                <hr />
              </div>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i className="fa fa-user" aria-hidden="true"></i>
              <div className='w-100'>
                <h4>+27 78 814 1278</h4>
                <p>Mon-Fri 8:30am-6:30pm</p>
                <hr/>
              </div>
            </div>
            <div className="d-flex align-items-center gap-4">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <div className='w-100'>
                <h4>rash@powerwitchery.com</h4>
                <p>24/7 online support</p>
                <hr />
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <iframe className='bg-info' width="100%" height="400" frameborder="0" style={{border:0}} src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGY-aKP5KQbjJbf2B2mN6u9909uI5iv50&amp;q=kyanja" allowfullscreen=""></iframe>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default Contact