import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "../css/Slider.css"


const slides = [
    {
        image: "/images/1.jpg",
        heading: "Awaken Your Magic ",
        subheading: "Step into a realm where ancient wisdom meets modern empowerment. Power Witchery is your sacred space for harnessing the forces of nature, energy work, and spiritual mastery. We offer guidance, tools, and inspiration to amplify your power. ",
    },
    {
        image: "/images/h1.jpg",
        heading: "Claim Your Power",
        subheading: "Explore spellcraft, divination, herbal magic, and energy work, all designed to help you manifest your desires and deepen your connection to the mystical world. Unveil Your Magic, Discover the Power Within ✨",
    },
    {
        image: "/images/16.jpg",
        heading: "Discover. Empower. Transform. 🌙",
        subheading: "Within the realms of our mystical sanctuary lies the key to unlocking boundless joy and unwavering contentment. Each enchantment crafted by our revered masters holds the promise of guiding you towards a life filled with profound happiness.",
    }
];

const Slidder = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [direction, setDirection] = useState(1); // 1 for next, -1 for previous

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prev) => (prev + 1) % slides.length);
        }, 15000); // Change slide every 10 seconds
        return () => clearInterval(interval);
    }, []);

    const handleNext = () => {
        setDirection(1);
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const handlePrev = () => {
        setDirection(-1);
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };


    return (
        <div className="slider">
            <AnimatePresence mode="wait">
                <motion.div
                    key={currentSlide}
                    className="inners"
                    initial={{ scale: 1.3, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    exit={{ scale: 1.1, opacity: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    style={{
                        backgroundImage: `url(${slides[currentSlide].image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                    }}
                >
                    <motion.div 
                        initial={{scale: 0, opacity: 0}}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 1, opacity: 0 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                        className="content" >
                        <motion.h1
                            className="text-danger"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1, duration: 1 }}
                        >
                            {slides[currentSlide].heading}
                        </motion.h1>
                        <motion.p
                            className="mt-4 text-success"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.5, duration: 1 }}
                        >
                            {slides[currentSlide].subheading}
                        </motion.p>
                    </motion.div>
                </motion.div>
            </AnimatePresence>

            {/* Navigation Buttons */}
            <button
                onClick={handlePrev}
                className="slideBtns btn1"
            >
                <i className="fa-solid fa-angle-left"></i>
            </button>
            <button
                onClick={handleNext}
                className="slideBtns btn2"
            >
                <i className="fa-solid fa-angle-right"></i>
            </button>
        </div>
    )
}


export default Slidder

/*
style={{ background: "linear-gradient(to bottom, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%)" }}
    <motion.div
        key={currentSlide}
        className="absolute w-full h-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}
        style={{
            backgroundImage: `url(${slides[currentSlide].image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
    <motion.div
        key={currentSlide}
        className="absolute w-full h-full"
        initial={{ x: direction * 100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: -direction * 100, opacity: 0 }}
        transition={{ duration: 1, ease: "easeInOut" }}
        style={{
            backgroundImage: `url(${slides[currentSlide].image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
*/