import React from 'react'
import { Link } from 'react-router-dom'
import "../css/Banner.css"

const Banner = ({page}) => {
  return (
    <div className="banner text-left">
        <h1 className="page-title text-capitalize">{page}</h1>
        <nav aria-label="breadcrumb"></nav>
        <ol className="breadcrumb">
            <li><Link className='text-danger'>Home </Link> <i className="fa-solid fa-angle-right pe-1"></i></li>
            <li className="active">{page}</li>
        </ol>
    </div>
  )
}

export default Banner