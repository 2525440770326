import React from 'react'
import Navigator from './Navigator'

import Body from './Body'
import Footer from './Footer'
import Top from './Top'
import Whatsapp from './Whatsapp'
import Slidder from './Slider'

const Entry = () => {
  return (
    <div>
        <Top />
        <Navigator />
        <Slidder />
        <Body />
        <Whatsapp />
        <Footer />
    </div>
  )
}

export default Entry